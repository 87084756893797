html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

body {
  background: lightslategray;
}

img {
  max-width: 100%;
  height: auto;
}
